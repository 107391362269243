
export default {
  components: {
    HomepageBestSellerProduct: () =>
      import(
        '@/containers/theme/HomepageBestSeller/HomepageBestSellerProduct.vue'
      ),
    CollectionProductItemSkeleton: () =>
      import('@/containers/collection/CollectionProductItemSkeleton.vue'),
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
}
